import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from 'react-router-dom';

// import '@uppy/core/dist/style.css';
// import '@uppy/dashboard/dist/style.css';

import {
  Courses, Upload, Approvals
} from './pages';

const cpaths = [
  {
    path: process.env.PUBLIC_URL + '/upload/:id',
    exact: false,
    body: Upload
  },
  {
    path: process.env.PUBLIC_URL + '/upload',
    exact: false,
    body: Upload
  },
  {
    path: process.env.PUBLIC_URL + '/approvals',
    exact: false,
    body: Approvals
  },
  {
    path: process.env.PUBLIC_URL + '/',
    body: Courses
  },
];

function App() {
  return (
    <Router>
      <Switch>
        {
          cpaths.map((route, index) => {
            return (
              <Route
                component={route.body}
                exact={route.exact}
                key={index}
                path={route.path}
              />
            );
          })
        }
        <Route
          component={() => (
            <Redirect
              to={{
                pathname: process.env.PUBLIC_URL + "/"
              }}
            />
          )}
        />
      </Switch>
    </Router>
  );
}

export default App;
