import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import 'moment/locale/it';

import { initApprovalPage, approveCourse } from '../action';
import { BasePage } from '.';

moment.locale('it-it');

export class Approvals extends Component {

  constructor (props) {
    super(props);
    this.state = {
      initFetch: true,

      // aproval lists
      todo: [],
      done: [],
    };
    this.initPage = this.initPage.bind(this);
  }

  componentDidMount(){
    this.initPage();
  }

  initPage() {
    initApprovalPage().then(
      r => {
        this.setState({
          todo: r.data.todo,
          initFetch: false,
        })
      }
    )
  }

  render() {
    return (
      <BasePage
        loading={this.state.initFetch}
      >
        <div
          className='fxcol'
          style={{
            marginBottom: '4em'
          }}
        >
          <div

          >

          </div>
          {
            this.state.todo.map(
              approval => (
                <div
                  className='fxrow mrg fxfill brdb'
                  key={`web-bdi-admin-aprovals-todo-${approval.id}`}
                >
                  <div>
                    <img
                      alt='placeholder'
                      src={`/api/v1/content/${approval.file}.jpg`}
                      style={{
                        height: '100px'
                      }}
                    />
                  </div>
                  <div
                    className='fxfill pdg'
                  >
                    <div
                      className='txt-title-2'
                    >
                      {approval.title}
                      &nbsp;
                      {
                        approval.thumbnail_extracted !== null?
                          (
                            <a
                              href={`${
                                process.env.REACT_APP_FRONT_END?
                                  process.env.REACT_APP_FRONT_END:
                                  'https://bdigital.supsi.ch'
                              }/course/${approval.id}`}
                              target='bdibital-preview'
                            >
                              mostra anteprima
                            </a>
                          ): null
                      }
                    </div>
                    <div
                      className='pdgb txt-subtitle txt-grey'
                    >
                      {moment(approval.registered).fromNow()}
                      &nbsp;
                      <span
                        style={{
                          color: '#787878'
                        }}
                      >
                        - {approval.registered}
                      </span>
                    </div>
                    <div>
                      {approval.description}
                    </div>
                  </div>
                  <div
                    className='pdg mrg'
                  >
                    collocazione:
                    <br />
                    {approval.collocation}
                  </div>
                  <div
                    className='pdg mrg'
                    style={{
                      whiteSpace: 'nowrap'
                    }}
                  >
                    post-processamento:
                    <br />
                    {
                      approval.thumbnail_started === null?
                        <span style={{ color: 'red'}}>In coda</span>:
                        approval.thumbnail_extracted === null?
                          <span style={{ color: 'orange'}}>In corso</span>:
                          <span style={{ color: 'green'}}>Completato</span>
                    }
                  </div>
                  <div
                    className='pdg mrg'
                  >
                      <button
                        disabled={approval.thumbnail_extracted === null}
                        onClick={
                          () => {
                            approveCourse(approval.id, approval.id_cat)
                              .then(
                                r => {
                                  alert("Approvazione completata. Il video è stato pubblicato.");
                                  this.initPage();
                                }
                              );
                          }
                        }
                        style={{
                          backgroundColor: approval.thumbnail_extracted === null?
                              "#71b081": "green",
                          "color": "white",
                          "padding": "1em"
                        }}
                      >
                        Approva
                      </button>
                  </div>
                  <div
                    className='pdg mrg'
                  >
                    <button
                      disabled={approval.thumbnail_extracted === null}
                      onClick={
                        () => {
                          approveCourse(approval.id, approval.id_cat, false)
                            .then(
                              r => {
                                alert("Approvazione negata. Il video non è ancora pubblicato.");
                                this.initPage();
                              }
                            );
                        }
                      }
                      style={{
                        backgroundColor: approval.thumbnail_extracted === null?
                            "#dd8181": "red",
                        "color": "white",
                        "padding": "1em"
                      }}
                    >
                        Respingi
                      </button>
                  </div>
                </div>
              )
            )
          }
        </div>
      </BasePage>
    );
  }

};

const mapStateToProps = (state) => ({
  
});

const mapDispatchToProps = {
  
};

export default connect(
  mapStateToProps, mapDispatchToProps
)(Approvals);
