
const initialState = {
  loading: false,
  authenticated: false,

  // UserInfo
  name: '',
  preferred_username: '',
  given_name: '',
  family_name: '',
  email: '',

  'x-hasura-allowed-roles': [],
  group: [],
};

function userReducer (state = {...initialState}, action){
  switch (action.type) {
    case 'AUTHENTICATED_OK':
      return {
        loading: false,
        authenticated: true,
        ...action.user,
      }
    default:
      return state
  }
}

export default userReducer;
