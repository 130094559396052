import React, { Component } from 'react';
import { connect } from 'react-redux';
import { delete_video, initCoursesPage } from '../action';
import {
  Link,
} from "react-router-dom";
import Center from '../common/center';
import { BasePage } from '.';
import Filter from '../common/filter';

export class Courses extends Component {

  constructor(props){
    super(props);

    this.textSearchTimeout = false;

    this.state = {

      delete_video: null,

      initFetch: true,
      courses: [],

      textSearch: '',

      collections: [],
      collectionsFilter: [],

      collocation: [],
      collocationFilter: [],

      typologies: [],
      typologiesFilter: [],

      languages: [],
      languagesFilter: [],

      licenses: [],
      licensesFilter: [],

      access: [],
      accessFilter: [],

    };
    this.loadCourses = this.loadCourses.bind(this);

    this.handleFilterChanged = this.handleFilterChanged.bind(this);
    this.setSearch = this.setSearch.bind(this);
  }

  componentDidMount(){
    this.loadCourses();
  }

  loadCourses(){
    const {
      textSearch,
      collectionsFilter,
      collocationFilter,
      typologiesFilter,
      languagesFilter,
      accessFilter,
      licensesFilter,
    } = this.state;

    const where = [];

    if (textSearch !== '') {
      where.push(`
        _or: [
          {
            title: {
              _ilike: "%${textSearch}%"
            }
          }, 
          {
            description: {
              _ilike: "%${textSearch}%"
            }
          }
        ]
      `)
    }

    if (collectionsFilter.length > 0) {
      where.push(`
        vid_cats: {
          typ: {_eq: "collection"},
          cat: {_in: ["${collectionsFilter.join('", "')}"]}
        }
      `)
    }

    if (collocationFilter.length > 0) {
      where.push(`collocation: {_in: ["${collocationFilter.join('", "')}"]}`)
    }

    if (typologiesFilter.length > 0) {
      where.push(`typology: {_in: ["${typologiesFilter.join('", "')}"]}`)
    }

    if (languagesFilter.length > 0) {
      where.push(`language: {_in: ["${languagesFilter.join('", "')}"]}`)
    }

    if (accessFilter.length > 0) {
      where.push(`access: {_in: ["${accessFilter.join('", "')}"]}`)
    }

    if (licensesFilter.length > 0) {
      where.push(`license: {_in: ["${licensesFilter.join('", "')}"]}`)
    }

    initCoursesPage(where).then(
      r => {
        this.setState({
          courses: r.data.courses,
          collections: r.data.collections,
          collocation: r.data.collocation,
          typologies: r.data.typologies,
          languages: r.data.languages,
          licenses: r.data.licenses,
          access: r.data.access,
          initFetch: false,
        })
      }
    )

  }

  handleFilterChanged (name, value) {
    this.setState({
      [name]: value
    }, () => {
      this.loadCourses();
    });
  }

  setSearch(text) {

    if (this.textSearchTimeout) {
      clearTimeout(this.textSearchTimeout);
      this.textSearchTimeout = false;
    }

    this.setState({
      textSearch: text
    }, () => {
      if (this.textSearchTimeout === false){
        this.textSearchTimeout = setTimeout(() => {
          this.loadCourses();
        }, 250);
      }
    });
  }

  render() {
    const {
      collections,
      collocation,
      typologies,
      languages,
      licenses,
      access
    } = this.state;
    return (
      <BasePage
        loading={this.state.initFetch}
        flexDirection='row'
      >
        <div
          style={{
            width: '300px',
            padding: '1em',
          }}
        >
          <div
            className='pdgb-x3'
          >
            <div
              className='txt-title-2 pdgb'
            >
              Ricerca testuale
            </div>
            <div
              className='txt-title-2 pdgb'
            >
              <input
                type='text'
                style={{
                  width: '100%',
                }}
                placeholder={`Cerca nel testo...`}
                onChange={
                  ev => {
                    this.setSearch(ev.target.value);
                  }
                }
                value={this.state.textSearch}
              />
            </div>
          </div>

          <Filter
            title='Collezione'
            data={collections}
            onChange={
              v => {
                this.handleFilterChanged('collectionsFilter', v);
              }
            }
          />
          <Filter
            title='Collocazione'
            data={collocation}
            onChange={
              v => {
                this.handleFilterChanged('collocationFilter', v);
              }
            }
          />
          <Filter
            title='Tipologia'
            data={typologies}
            onChange={
              v => {
                this.handleFilterChanged('typologiesFilter', v);
              }
            }
          />
          <Filter
            title='Lingua'
            data={languages}
            onChange={
              v => {
                this.handleFilterChanged('languagesFilter', v);
              }
            }
          />
          <Filter
            title='Tipo di accesso'
            data={access}
            onChange={
              v => {
                this.handleFilterChanged('accessFilter', v);
              }
            }
          />
          <Filter
            title='Licenza'
            data={licenses}
            onChange={
              v => {
                this.handleFilterChanged('licensesFilter', v);
              }
            }
          />
        </div>
        {
          this.state.courses.length === -1?
            <Center>
              Non hai caricato ancora nessun corso
            </Center>:
            <div
              className='fxfill fxcol'
            >
              {
                this.state.courses.map(
                  course => (
                    <div
                      className='mrg brdb'
                      key={`web-bdigial-admin-course-${course.id}`}
                    >
                      <div
                        className='fxrowc fxfill'
                      >
                        <div>
                          <img
                            alt='placeholder'
                            src={`/api/v1/content/${course.file}.jpg`}
                            style={{
                              height: '100px'
                            }}
                          />
                        </div>
                        <div
                          className='fxfill pdg'
                        >
                          <div
                            className='txt-title-2'
                          >
                            {course.title}
                          </div>
                          <div
                            style={{
                              fontSize: '0.8em'
                            }}
                          >
                            {
                              course.approvations.map(
                                (approvation, idx) => (
                                  <div
                                    key={`web-bdigital-admin-courses-approvation-${idx}`}
                                  >
                                    {approvation.name}: {
                                      approvation.approved === null?
                                        <span className='txt-orange'>
                                          In attesa dell'approvazione da parte di
                                          &nbsp;
                                          <a
                                            className='linker'
                                            href={`mailto: ${approvation.email}`}
                                          >
                                            {approvation.first_name} {approvation.last_name}
                                          </a>
                                        </span>:
                                        approvation.approved === true?
                                          <span className='txt-green'>
                                            👍 Approvato da parte di
                                            &nbsp;
                                            <a
                                              className='linker'
                                              href={`mailto: ${approvation.email}`}
                                            >
                                              {approvation.first_name} {approvation.last_name}
                                            </a>
                                          </span>:
                                          <span className='txt-red'>
                                            😭 Respinto da parte di
                                            &nbsp;
                                            <a
                                              className='linker'
                                              href={`mailto: ${approvation.email}`}
                                            >
                                              {approvation.first_name} {approvation.last_name}
                                            </a>
                                          </span>
                                    }
                                  </div>
                                )
                              )
                            }
                          </div>
                          <div>
                            {course.description}
                          </div>
                        </div>
                        <div className='fxfill' />
                        <div
                          className='pdg mrg'
                        >
                          {
                            this.state.delete_video === course.id?
                              <span>
                                Sicuro di voler eliminare il corso definitivamente?
                                <br />
                                <span
                                  className='linker'
                                  onClick={
                                    () => {
                                      delete_video(
                                        course.id
                                      ).then(
                                        r => {
                                          this.loadCourses();
                                        }
                                      )
                                    }
                                  }
                                  style={{
                                    color: 'red'
                                  }}
                                >
                                  Sì
                                </span>
                                &nbsp;&nbsp;
                                <span
                                  className='linker'
                                  onClick={
                                    () => {
                                      this.setState({
                                        delete_video: 'ciao'
                                      });
                                    }
                                  }
                                >
                                  Annulla
                                </span>

                              </span>:
                              <span
                                className='linker'
                                onClick={
                                  () => {
                                    this.setState({
                                      delete_video: course.id
                                    });
                                  }
                                }
                                style={{
                                  color: 'red'
                                }}
                              >
                                Elimina
                              </span>
                          }
                        </div>
                        <div
                          className='pdg mrg'
                        >
                          <Link
                            className='linker'
                            to={`${process.env.PUBLIC_URL}/upload/${course.id}`}
                          >
                            Modifica
                          </Link>
                        </div>
                        <div
                          className='pdg mrg'
                        >
                          <a
                            className='linker'
                            href={`${
                              process.env.REACT_APP_FRONT_END?
                                process.env.REACT_APP_FRONT_END:
                                'https://bdigital.supsi.ch'
                            }/course/${course.id}`}
                            target='bdibital-preview'
                          >
                            Apri
                          </a>
                        </div>
                      </div>
                    </div>
                  )
                )
              }
            </div>
        }
      </BasePage>
    );
  }
};

const mapStateToProps = (state) => ({
  
});

const mapDispatchToProps = {
  
};

export default connect(mapStateToProps, mapDispatchToProps)(Courses);
