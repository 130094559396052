import React, { useState } from 'react';
import PropTypes from 'prop-types';

function Filter ({ title, data, onChange }) {

  const [ search, setSearch ] = useState('');
  const [ selected, setSelected ] = useState([]);

  // When data lenght lenght greater then scrollGreaterThen
  // the enable scrolling and search input
  const scrollGreaterThen = 4;

  return (
    <div
      className='pdgb-x3'
    >
      <div
        className='txt-title-2 pdgb'
      >
        { title }
      </div>
      {
        data.length > scrollGreaterThen?
          <div
            className='txt-title-2 pdgb'
          >
            <input
              type='text'
              style={{
                width: '100%',
              }}
              placeholder={`Cerca ${title.toLowerCase()}...`}
              onChange={
                ev => {
                  setSearch(ev.target.value);
                }
              }
              value={search}
            />
          </div>: null
      }
      <div
        className='pdgtb'
        style={{
          ...(
            data.length > scrollGreaterThen?
              {
                height: '8em',
                overflowY: 'scroll'
              }: null
          ),
        }}
      >
        {
          data.map(
            (el, idx) => (
              search === ''
              || el.name.normalize(
                "NFD"
              ).replace(
                /[\u0300-\u036f]/g, ""
              ).toLowerCase().includes(search)?
                <div
                  className='pdglr'
                  key={idx}
                  style={{
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                  }}
                >
                  <label>
                    <input
                      type="checkbox"
                      checked={selected.includes(el.id)}
                      onChange={e => {
                        if (e.target.checked) {
                          const tmpSelected = [...selected, el.id];
                          setSelected(tmpSelected);
                          if (onChange) {
                            onChange(tmpSelected);
                          }
                        } else {
                          const idx = selected.indexOf(el.id);
                          if (idx>=0){
                            const tmpSelected = [...selected];
                            tmpSelected.splice(idx, 1);
                            setSelected(tmpSelected);
                            if (onChange) {
                              onChange(tmpSelected);
                            }
                          }
                        }
                      }}
                    />
                    <span>
                      {el.name}
                    </span>
                  </label>
                </div>: null
            )
          )
        }
      </div>
    </div>
  );
};

Filter.propTypes = {
  title: PropTypes.string,
  data: PropTypes.array,
  selected: PropTypes.array,
  onChange: PropTypes.func
};

// Filter.defaultProps = {
//   title: '',
//   data: [],
//   selected: []
// };

export default Filter;