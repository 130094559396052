import React, { Component } from "react";
import { connect } from "react-redux";
import Uppy from "@uppy/core";
import XHRUpload from "@uppy/xhr-upload";
import { Dashboard } from "@uppy/react";
import moment from "moment";

import "@uppy/core/dist/style.css";
import "@uppy/dashboard/dist/style.css";
import { addVideo, initUploadPage } from "../action";
import Video from "../common/video";
import Button from "../common/button";
import { BasePage } from ".";

export class Upload extends Component {
  constructor(props) {
    super(props);

    this.generatePassword = this.generatePassword.bind(this);
    this.isValid = this.isValid.bind(this);
    this.alert = this.alert.bind(this);

    this.state = {
      initFetch: true,

      // video metadata
      video: {
        collections: [],
        authors: [],
        links: [],
        collocation: "",
        typology: "",
        creation_date: moment().format("YYYY-MM-DD"),
        title: "",
        description: "",
        language: "",
        keywords: [],
        access: "",
        password: this.generatePassword(),
        license: "",
        file: null,
        link_msteams: "",
        link_icorsi: "",
        valid_from: "",
        valid_to: "",
      },

      // Helpers
      keyword: "",
      authorAffiliation: "",
      authorName: "",
      authorEmail: "",

      linkUrl: "",
      linkText: "",

      // validation: {
      //   collections: false,
      //   collocation: false,
      //   typology: false,
      //   creation_date: false,
      //   title: false,
      //   description: false,
      //   language: false,
      //   access: false,
      //   license: false,
      // },

      // Categories
      collections: [],
      collocations: [],
      typologies: [],
      languages: [],
      accesses: [],
      licenses: [],

      // File upload properties
      uploadEnabled: true,
      uploading: false,
      uploaded: false,
      fileuuid: "",
    };

    this.uppy = new Uppy({
      restrictions: {
        allowedFileTypes: [".mpeg", ".avi", ".mp4"],
        maxNumberOfFiles: 1,
      },
      id: "uppy1",
      autoProceed: true,
      debug: false,
      // meta: {
      //   folder: ''
      // }
    })
      .use(XHRUpload, {
        method: "PUT",
        endpoint: "",
        formData: false,
        getResponseData: (responseText, response) => {
          return {};
        },
      })
      .on("upload-success", (file, response) => {
        // console.log(response.status); // HTTP status code
        // console.log(response.body);   // extracted response data
        this.setState({
          uploading: false,
          uploaded: true,
          video: {
            ...this.state.video,
            file: `${this.state.fileuuid}.${file.extension}`,
            type: file.type,
          },
        });
      })
      .on("upload-error", (file, response) => {
        console.log(response.status); // HTTP status code
        console.log(response.body); // extracted response data
      })
      .on("restriction-failed", (file, error) => {
        console.log(error); // extracted response data
      })
      // .on('file-added', (file) => {
      //   console.log('Added file', file)
      // })
      .on("upload", (data) => {
        const file = this.uppy.getFile(data.fileIDs[0]);
        const { xhrUpload } = this.uppy.getState();
        this.uppy.setState({
          xhrUpload: {
            ...xhrUpload,
            endpoint: `${process.env.PUBLIC_URL}/api/v1/content/${this.state.fileuuid}.${file.extension}`,
          },
        });
        this.setState({
          uploading: true,
          file: `${this.state.fileuuid}.${file.extension}`,
        });
      });
  }

  componentDidMount() {
    const { match } = this.props;

    if (match.params.hasOwnProperty("id")) {
      initUploadPage(match.params.id).then((r) => {
        this.setState({
          initFetch: false,
          uploadEnabled: false,
          fileuuid: r.data.bdi_uuid_generate_v4[0].uuid,
          collections: r.data.collections,
          collocations: r.data.collocations,
          typologies: r.data.typologies,
          languages: r.data.languages,
          accesses: r.data.accesses,
          licenses: r.data.licenses,
          video: {
            ...r.data.video[0],
          },
        });
      });
    } else {
      initUploadPage().then((r) => {
        this.setState({
          initFetch: false,
          uploadEnabled: true,
          fileuuid: r.data.bdi_uuid_generate_v4[0].uuid,
          collections: r.data.collections,
          collocations: r.data.collocations,
          typologies: r.data.typologies,
          languages: r.data.languages,
          accesses: r.data.accesses,
          licenses: r.data.licenses,
          video: {
            collections: [],
            authors: [],
            links: [],
            collocation: "",
            typology: "",
            creation_date: moment().format("YYYY-MM-DD"),
            title: "",
            description: "",
            language: r.data.languages[0].id,
            keywords: [],
            access: "",
            password: this.generatePassword(),
            valid_from: "",
            valid_to: "",
            license: "",
            file: null,
            link_msteams: "",
            link_icorsi: "",
          },
        });
      });
    }
  }

  alert(msg) {
    alert(msg);
  }

  isValid() {
    const { video } = this.state;

    const cns = {
      collections: 1,
      collocation: true,
      typology: true,
      creation_date: true,
      title: true,
      description: true,
      language: true,
      authors: 1,
      keywords: 0,
      links: 0,
      access: true,
      license: true,
    };

    if (video.collections.length < cns.collections) {
      this.alert("Devi scegliere almeno una collezione");
      return false;
    }

    if (cns.collections === true && !video.collocation) {
      this.alert("La collocazione è obbligatoria");
      return false;
    }

    if (cns.typology === true && !video.typology) {
      this.alert("La tipologia è obbligatoria");
      return false;
    }

    if (cns.creation_date === true && !video.creation_date) {
      this.alert("La data di creazione è obbligatoria");
      return false;
    }

    if (cns.title === true && !video.title) {
      this.alert("Il titolo è obbligatoria");
      return false;
    }

    // if (cns.description === true && !video.description) {
    //   this.alert("La descrizione è obbligatoria");
    //   return false;
    // }

    if (cns.language === true && !video.language) {
      this.alert("La descrizione è obbligatoria");
      return false;
    }

    if (video.authors.length < cns.authors) {
      this.alert("Devi scegliere almeno un autore");
      return false;
    }

    if (video.keywords.length < cns.keywords) {
      this.alert("Devi scegliere almeno una keyword");
      return false;
    }

    if (video.links.length < cns.links) {
      this.alert("Devi scegliere almeno un link");
      return false;
    }

    if (cns.access === true && !video.access) {
      this.alert("La scelta della tipologia di accesso è obbligatorio");
      return false;
    }

    if (cns.license === true && !video.license) {
      this.alert("La scelta della licenza è obbligatoria");
      return false;
    }

    return true;
  }

  generatePassword() {
    var length = 6,
      charset =
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
      retVal = "";
    for (var i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
  }

  render() {
    return (
      <BasePage>
        <div
          className="fxcolc"
          style={{
            marginBottom: "4em",
          }}
        >
          <div>
            <h1>Carica un nuovo video</h1>
            {/* {this.state.fileuuid} */}
          </div>

          {/** Caricamento files */}
          {this.state.uploadEnabled === false && this.state.video.id ? (
            <div
              className="mrg fxcolc"
              style={{
                width: "750px",
              }}
            >
              <div
                className="linker pdgb"
                onClick={() => {
                  this.setState({
                    uploadEnabled: true,
                  });
                }}
              >
                Carica un nuovo video...
              </div>
              <Video
                file={this.state.video.file}
                poster={`${this.state.video.file}.jpg`}
              />
              {
                // add the full url to the video, into a readony text field
                // with copy content button
              }
              <div
                className="fxrow"
                style={{
                  width: "100%",
                }}
              >
                <input
                  type="text"
                  style={{
                    width: "100%",
                  }}
                  readOnly
                  value={
                    `${
                      process.env.REACT_APP_FRONT_END
                        ? process.env.REACT_APP_FRONT_END
                        : "https://bdigital.supsi.ch"
                    }/api/v1/content/${this.state.video.file}`
                  }
                />
                <button
                  onClick={() => {
                    try {
                      window.navigator.clipboard.writeText(
                        `${
                          process.env.REACT_APP_FRONT_END
                            ? process.env.REACT_APP_FRONT_END
                            : "https://bdigital.supsi.ch"
                        }/api/v1/content/${this.state.video.file}`
                      );
                    } catch (err) {
                      console.error(err);
                      alert("Copia non riuscita, seleziona il testo e copia manualmente");
                    }
                  }}
                >
                  Copia
                </button>
              </div>
            </div>
          ) : null}
          {this.state.uploadEnabled === true ? (
            <div className="brd mrg">
              {this.state.uploaded === false &&
              this.state.uploadEnabled === true &&
              this.state.video.id ? (
                <div className="pdg">
                  <div className="txt-red">
                    Il nuovo video sostituirà quello precendente.
                  </div>
                  <div
                    className="linker"
                    onClick={() => {
                      this.setState({
                        uploadEnabled: false,
                      });
                    }}
                  >
                    Annulla
                  </div>
                </div>
              ) : null}
              {this.state.uploaded === true ? (
                <div
                  style={{
                    height: "400px",
                    width: "750px",
                  }}
                >
                  Caricamento Completato.
                </div>
              ) : (
                <Dashboard
                  height={400}
                  showProgressDetails
                  uppy={this.uppy}
                  // plugins={['XHRUpload']}
                  proudlyDisplayPoweredByUppy={false}
                  metaFields={[
                    { id: "name", name: "Name", placeholder: "File name" },
                  ]}
                />
              )}
            </div>
          ) : null}

          {/** COLLEZIONE */}
          <div
            style={{
              textAlign: "left",
            }}
          >
            <h4>Collezione</h4>
          </div>
          <div
            className="brd mrg pdg fxcol"
            style={{
              width: "750px",
            }}
          >
            <div className="fxrowc fxfill">
              <div className="fxfill">
                <select
                  name="lang"
                  onChange={(ev) => {
                    const selected = this.state.collections.find(
                      (selected) => ev.target.value === selected.id
                    );
                    if (selected) {
                      this.setState({
                        video: {
                          ...this.state.video,
                          collections: [
                            ...this.state.video.collections,
                            selected,
                          ],
                        },
                      });
                    }
                  }}
                  style={{
                    width: "100%",
                  }}
                >
                  <option value="">Scegli una più collezioni...</option>
                  {this.state.collections
                    .filter(
                      (collection) =>
                        this.state.video.collections.find(
                          (selected) => collection.id === selected.id
                        ) === undefined
                    )
                    .map((collection) => (
                      <option
                        key={`opt-collection-${collection.id}`}
                        value={collection.id}
                      >
                        {collection.name}
                      </option>
                    ))}
                </select>
              </div>
            </div>
            <div className="fxcol fxfill">
              {this.state.video.collections.map((collection) => (
                <div key={`sel-collection-${collection.id}`} className="pdg">
                  {collection.name}{" "}
                  <span
                    className="linker-small"
                    onClick={() => {
                      var index = this.state.video.collections.findIndex(
                        (el) => collection.id === el.id
                      );
                      var collections = [...this.state.video.collections];
                      collections.splice(index, 1);
                      this.setState({
                        video: {
                          ...this.state.video,
                          collections: collections,
                        },
                      });
                    }}
                  >
                    - rimuovi
                  </span>
                </div>
              ))}
            </div>
            <div className="fxrowc fxfill pdg">
              <div
                className="fxfill"
                style={{
                  color: "#787878",
                }}
              >
                {/* Ut enim ad minim veniam, quis nostrud exercitation
                ullamco laboris nisi ut aliquip ex ea commodo consequat. */}
              </div>
            </div>
          </div>

          {/** COLLOCATION */}
          <div
            style={{
              textAlign: "left",
            }}
          >
            <h4>Collocazione</h4>
          </div>
          <div
            className="brd mrg pdg fxcol"
            style={{
              width: "750px",
            }}
          >
            <div className="fxrowc fxfill">
              <div className="fxfill">
                {/* <input
                  type='text'
                  style={{
                    width: '100%'
                  }}
                /> */}
                <select
                  name="lang"
                  onChange={(ev) => {
                    this.setState({
                      video: {
                        ...this.state.video,
                        collocation: ev.target.value,
                      },
                    });
                  }}
                  style={{
                    width: "100%",
                  }}
                  value={this.state.video.collocation}
                >
                  <option value="">Scegli una più collocazione...</option>
                  {this.state.collocations.map((collocation) => (
                    <option
                      key={`opt-collocation-${collocation.id}`}
                      value={collocation.id}
                    >
                      {" + ".repeat(collocation.path.split(".").length)}{" "}
                      {collocation.name} - {collocation.description}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="fxrowc fxfill pdg">
              <div
                className="fxfill"
                style={{
                  color: "#787878",
                }}
              >
                Scegliere una collocazione all’interno della quale caricare il
                proprio video. La collocazione è il “committente” del video, in
                altre parole dovete indicare quale dipartimento vi ha chiesto di
                registrare la pillola.
              </div>
            </div>
          </div>

          {/** Selezione tipologia */}
          <div>
            <h4>Tipologia</h4>
          </div>
          <div
            className="brd mrg pdg fxrowsa"
            style={{
              width: "750px",
            }}
          >
            {this.state.typologies.map((typology) => (
              <div
                key={`opt-typology-${typology.id}`}
                style={{ textAlign: "center" }}
              >
                {typology.name}
                <br />
                <input
                  type="radio"
                  checked={typology.id === this.state.video.typology}
                  onChange={(ev) => {
                    this.setState({
                      video: {
                        ...this.state.video,
                        typology: ev.target.value,
                      },
                    });
                  }}
                  value={typology.id}
                />
              </div>
            ))}
          </div>

          {/** Informazione di base */}
          <div>
            <h4>Informazione di base</h4>
          </div>
          <div
            className="brd mrg pdg fxcol"
            style={{
              width: "750px",
            }}
          >
            <div className="fxrowc fxfill">
              <div
                style={{
                  width: "200px",
                  textAlign: "right",
                  paddingRight: "1em",
                }}
              >
                Data di creazione:
              </div>
              <div className="fxfill">
                <input
                  type="date"
                  style={{
                    width: "100%",
                  }}
                  onChange={(ev) => {
                    this.setState({
                      video: {
                        ...this.state.video,
                        creation_date: ev.target.value,
                      },
                    });
                  }}
                  value={this.state.video.creation_date}
                />
              </div>
            </div>
            <div className="fxrowc fxfill pdg">
              <div
                style={{
                  width: "200px",
                }}
              ></div>
              <div
                className="fxfill"
                style={{
                  color: "#787878",
                }}
              >
                indicare la data di creazione del contenuto.
              </div>
            </div>

            <div className="fxrowc fxfill">
              <div
                style={{
                  width: "200px",
                  textAlign: "right",
                  paddingRight: "1em",
                }}
              >
                Titolo:
              </div>
              <div className="fxfill">
                <input
                  type="text"
                  style={{
                    width: "100%",
                  }}
                  onChange={(ev) =>
                    this.setState({
                      video: {
                        ...this.state.video,
                        title: ev.target.value,
                      },
                    })
                  }
                  value={this.state.video.title}
                />
              </div>
            </div>

            <div className="fxrowc fxfill pdg">
              <div
                style={{
                  width: "200px",
                }}
              ></div>
              <div
                className="fxfill"
                style={{
                  color: "#787878",
                }}
              >
                Assegnare un titolo al contenuto. Il titolo deve essere
                descrittivo dei concetti contenuti all’interno del video.
              </div>
            </div>

            {/* <div
              className='fxrowc fxfill'
            >
              <div
                style={{
                  width: '200px',
                  textAlign: 'right',
                  paddingRight: '1em'
                }}
              >
                Descrizione:
              </div> 
              <div
                className='fxfill'
              >
                <textarea
                  type='text'
                  style={{
                    width: '100%'
                  }}
                  rows='5'
                  onChange={
                    ev => this.setState({
                      video: {
                        ...this.state.video,
                        description: ev.target.value
                      }
                    })
                  }
                  value={this.state.video.description}
                />
              </div> 
            </div>

            <div
              className='fxrowc fxfill pdg'
            >
              <div
                style={{
                  width: '200px'
                }}
              >
                
              </div> 
              <div
                className='fxfill'
                style={{
                  color: '#787878'
                }}
              >
                Descrivere brevemente il video. Suggerimento: un utente, semplicemente
                leggendo questa descrizione, dovrebbe essere in grado di capire quali
                argomenti verranno trattati all’interno della pillola.
              </div> 
            </div> */}

            <div className="fxrowc fxfill">
              <div
                style={{
                  width: "200px",
                  textAlign: "right",
                  paddingRight: "1em",
                }}
              >
                Lingua:
              </div>
              <div className="fxfill">
                <select
                  name="lang"
                  onChange={(ev) => {
                    this.setState({
                      video: {
                        ...this.state.video,
                        language: ev.target.value,
                      },
                    });
                  }}
                  style={{
                    width: "100%",
                  }}
                  value={this.state.video.language}
                >
                  {this.state.languages.map((language) => (
                    <option
                      key={`opt-language-${language.id}`}
                      value={language.id}
                    >
                      {language.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="fxrowc fxfill pdg">
              <div
                style={{
                  width: "200px",
                }}
              ></div>
              <div
                className="fxfill"
                style={{
                  color: "#787878",
                }}
              >
                Inserire la lingua di registrazione della pillola che si sta
                caricando.
              </div>
            </div>

            {/** AUTORI */}
            <div className="fxrowc fxfill">
              <div
                style={{
                  width: "200px",
                  textAlign: "right",
                  paddingRight: "1em",
                }}
              >
                Autori:
              </div>
              <div className="fxfill fxrowc">
                <input
                  type="text"
                  style={{
                    width: "100%",
                  }}
                  placeholder="Nome e cognome"
                  ref={(inputEl) => (this.authorName = inputEl)}
                  onKeyPress={(ev) => {
                    if (ev.key === "Enter" && ev.target.value) {
                      this.setState(
                        {
                          authorName: "",
                          authorAffiliation: "",
                          video: {
                            ...this.state.video,
                            authors: [
                              ...this.state.video.authors,
                              {
                                name: this.state.authorName,
                                affiliation: this.state.authorAffiliation,
                                email: this.state.authorEmail,
                              },
                            ],
                          },
                        },
                        () => {
                          this.authorName.focus();
                        }
                      );
                    }
                  }}
                  onChange={(ev) =>
                    this.setState({
                      authorName: ev.target.value,
                    })
                  }
                  value={this.state.authorName}
                />
                <input
                  type="text"
                  style={{
                    width: "100%",
                    marginLeft: "0.5em",
                  }}
                  placeholder="Affiliazione"
                  onKeyPress={(ev) => {
                    if (ev.key === "Enter" && ev.target.value) {
                      this.setState(
                        {
                          authorName: "",
                          authorAffiliation: "",
                          video: {
                            ...this.state.video,
                            authors: [
                              ...this.state.video.authors,
                              {
                                name: this.state.authorName,
                                affiliation: this.state.authorAffiliation,
                                email: this.state.authorEmail,
                              },
                            ],
                          },
                        },
                        () => {
                          this.authorName.focus();
                        }
                      );
                    }
                  }}
                  onChange={(ev) =>
                    this.setState({
                      authorAffiliation: ev.target.value,
                    })
                  }
                  value={this.state.authorAffiliation}
                />
                <input
                  type="text"
                  style={{
                    width: "100%",
                    marginLeft: "0.5em",
                  }}
                  placeholder="Email"
                  onKeyPress={(ev) => {
                    if (ev.key === "Enter" && ev.target.value) {
                      this.setState(
                        {
                          authorName: "",
                          authorAffiliation: "",
                          video: {
                            ...this.state.video,
                            authors: [
                              ...this.state.video.authors,
                              {
                                name: this.state.authorName,
                                affiliation: this.state.authorAffiliation,
                                email: this.state.authorEmail,
                              },
                            ],
                          },
                        },
                        () => {
                          this.authorName.focus();
                        }
                      );
                    }
                  }}
                  onChange={(ev) =>
                    this.setState({
                      authorEmail: ev.target.value,
                    })
                  }
                  value={this.state.authorEmail}
                />
                <div
                  style={{
                    width: "100%",
                    marginLeft: "0.5em",
                  }}
                >
                  <Button
                    label="Aggiungi"
                    onClick={() => {
                      this.setState(
                        {
                          authorName: "",
                          authorAffiliation: "",
                          video: {
                            ...this.state.video,
                            authors: [
                              ...this.state.video.authors,
                              {
                                name: this.state.authorName,
                                affiliation: this.state.authorAffiliation,
                                email: this.state.authorEmail,
                              },
                            ],
                          },
                        },
                        () => {
                          this.authorName.focus();
                        }
                      );
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="fxrowc fxfill pdg">
              <div
                style={{
                  width: "200px",
                }}
              ></div>
              <div className="fxfill">
                {this.state.video.authors.map((author, idx) => (
                  <div key={`list-author-${idx}`} className="pdgt">
                    {author.name}, {author.affiliation}, {author.email}
                    &nbsp;
                    <Button
                      label="Rimuovi"
                      onClick={() => {
                        var index = this.state.video.authors.findIndex(
                          (el) =>
                            author.name === el.name &&
                            author.affiliation === el.affiliation
                        );
                        var authors = [...this.state.video.authors];
                        authors.splice(index, 1);
                        this.setState({
                          video: {
                            ...this.state.video,
                            authors: authors,
                          },
                        });
                      }}
                    />
                  </div>
                ))}
                <div
                  className="pdgt"
                  style={{
                    color: "#787878",
                  }}
                >
                  Inserire nome e cognome dell’autore (o degli autori) della
                  pillola in oggetto, nonché l’affiliazione alla società di
                  appartenenza e l’indirizzo mail.
                </div>
              </div>
            </div>

            {/** PAROLE CHIAVE */}
            <div className="fxrowc fxfill">
              <div
                style={{
                  width: "200px",
                  textAlign: "right",
                  paddingRight: "1em",
                }}
              >
                Parole chiave:
              </div>
              <div className="fxfill fxrowc">
                <input
                  type="text"
                  style={{
                    width: "100%",
                  }}
                  onKeyPress={(ev) => {
                    if (ev.key === "Enter" && ev.target.value) {
                      this.setState({
                        keyword: "",
                        video: {
                          ...this.state.video,
                          keywords: [
                            ...this.state.video.keywords,
                            this.state.keyword,
                          ],
                        },
                      });
                    }
                  }}
                  onChange={(ev) =>
                    this.setState({
                      keyword: ev.target.value,
                    })
                  }
                  value={this.state.keyword}
                />
                <div
                  style={{
                    width: "100%",
                    marginLeft: "0.5em",
                  }}
                >
                  <Button
                    label="Aggiungi"
                    onClick={() => {
                      this.setState({
                        keyword: "",
                        video: {
                          ...this.state.video,
                          keywords: [
                            ...this.state.video.keywords,
                            this.state.keyword,
                          ],
                        },
                      });
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="fxrowc fxfill pdg">
              <div
                style={{
                  width: "200px",
                }}
              ></div>
              <div className="fxfill">
                {this.state.video.keywords.map((keyword, idx) => (
                  <div key={`list-keyword-${idx}`} className="pdgt">
                    {keyword}
                    &nbsp;
                    <Button
                      label="Rimuovi"
                      onClick={() => {
                        var index = this.state.video.keywords.findIndex(
                          (el) => keyword === el
                        );
                        var keywords = [...this.state.video.keywords];
                        keywords.splice(index, 1);
                        this.setState({
                          video: {
                            ...this.state.video,
                            keywords: keywords,
                          },
                        });
                      }}
                    />
                  </div>
                ))}
                <div
                  className="pdgt"
                  style={{
                    color: "#787878",
                  }}
                >
                  Inserire le parole chiave (o tag) da associare al video. Le
                  parole chiave sono le parole che identificano al meglio il
                  video che si vuole caricare. Grazie ai tag sarà possibile
                  ricercate tutti i video presenti in bDigital con quel tag.
                </div>
              </div>
            </div>
          </div>

          {/** LINK INTERNI */}
          <div>
            <h4>Links</h4>
          </div>
          <div
            className="brd mrg pdg fxcol"
            style={{
              width: "750px",
            }}
          >
            <div className="fxrowc fxfill">
              <div
                style={{
                  width: "200px",
                  textAlign: "right",
                  paddingRight: "1em",
                }}
              >
                iCorsi
              </div>
              <div className="fxfill fxrowc">
                <input
                  type="text"
                  style={{
                    width: "100%",
                    marginLeft: "0.5em",
                  }}
                  placeholder="Indirizzo completo"
                  onChange={(ev) =>
                    this.setState({
                      video: {
                        ...this.state.video,
                        link_icorsi: ev.target.value,
                      },
                    })
                  }
                  value={this.state.video.link_icorsi}
                />
              </div>
            </div>
            <div className="fxrowc fxfill pdgt">
              <div
                style={{
                  width: "200px",
                  textAlign: "right",
                  paddingRight: "1em",
                }}
              >
                MSTeams
              </div>
              <div className="fxfill fxrowc">
                <input
                  type="text"
                  style={{
                    width: "100%",
                    marginLeft: "0.5em",
                  }}
                  placeholder="Indirizzo completo"
                  onChange={(ev) =>
                    this.setState({
                      video: {
                        ...this.state.video,
                        link_msteams: ev.target.value,
                      },
                    })
                  }
                  value={this.state.video.link_msteams}
                />
              </div>
            </div>
          </div>

          {/** ALTRI LINK */}
          <div>
            <h4>Altri links</h4>
          </div>
          <div
            className="brd mrg pdg fxcol"
            style={{
              width: "750px",
            }}
          >
            <div className="fxrowc fxfill">
              <div
                style={{
                  width: "200px",
                  textAlign: "right",
                  paddingRight: "1em",
                }}
              >
                Link:
              </div>
              <div className="fxfill fxrowc">
                <input
                  type="text"
                  style={{
                    width: "100%",
                    marginRight: "0.5em",
                  }}
                  placeholder="Testo visibile"
                  ref={(inputEl) => (this.linkText = inputEl)}
                  onKeyPress={(ev) => {
                    if (ev.key === "Enter" && ev.target.value) {
                      this.setState(
                        {
                          linkUrl: "",
                          linkText: "",
                          video: {
                            ...this.state.video,
                            links: [
                              ...this.state.video.links,
                              {
                                url: this.state.linkUrl,
                                text: this.state.linkText,
                              },
                            ],
                          },
                        },
                        () => {
                          this.linkText.focus();
                        }
                      );
                    }
                  }}
                  onChange={(ev) =>
                    this.setState({
                      linkText: ev.target.value,
                    })
                  }
                  value={this.state.linkText}
                />
                <input
                  type="text"
                  style={{
                    width: "100%",
                    marginLeft: "0.5em",
                  }}
                  placeholder="Indirizzo completo"
                  onKeyPress={(ev) => {
                    if (ev.key === "Enter" && ev.target.value) {
                      this.setState(
                        {
                          linkUrl: "",
                          linkText: "",
                          video: {
                            ...this.state.video,
                            links: [
                              ...this.state.video.links,
                              {
                                url: this.state.linkUrl,
                                text: this.state.linkText,
                              },
                            ],
                          },
                        },
                        () => {
                          this.linkText.focus();
                        }
                      );
                    }
                  }}
                  onChange={(ev) =>
                    this.setState({
                      linkUrl: ev.target.value,
                    })
                  }
                  value={this.state.linkUrl}
                />
                <div
                  style={{
                    width: "100%",
                    marginLeft: "0.5em",
                  }}
                >
                  <Button
                    label="Aggiungi"
                    onClick={() => {
                      this.setState(
                        {
                          linkUrl: "",
                          linkText: "",
                          video: {
                            ...this.state.video,
                            links: [
                              ...this.state.video.links,
                              {
                                url: this.state.linkUrl,
                                text: this.state.linkText,
                              },
                            ],
                          },
                        },
                        () => {
                          this.linkText.focus();
                        }
                      );
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="fxrowc fxfill pdg">
              <div
                style={{
                  width: "200px",
                }}
              />
              <div
                className="fxfill"
                style={{
                  color: "#787878",
                }}
              >
                Inserire i link alle risorse esterne. Tali risorse possono
                essere, ad esempio, i testi pdf di esercizi caricati su iCorsi,
                link a video YouTube, link a lezioni di Microsoft Teams ecc.
              </div>
            </div>

            <div className="fxrowc fxfill pdg">
              <div
                style={{
                  width: "200px",
                }}
              ></div>
              <div className="fxfill">
                {this.state.video.links.map((link, idx) => (
                  <div key={`list-links-${idx}`} className="pdgt">
                    <a href={link.url} target="_BLANK" rel="noreferrer">
                      {link.text}
                    </a>
                    &nbsp;
                    <Button
                      label="Rimuovi"
                      onClick={() => {
                        var index = this.state.video.links.findIndex(
                          (el) => link.text === el.text && link.url === el.url
                        );
                        var links = [...this.state.video.links];
                        links.splice(index, 1);
                        this.setState({
                          video: {
                            ...this.state.video,
                            links: links,
                          },
                        });
                      }}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>

          {/** LICENZA */}
          <div>
            <h4>Diritti di accesso</h4>
          </div>
          <div
            className="brd mrg pdg fxcol"
            style={{
              width: "750px",
            }}
          >
            <div
              className="fxrowsa"
              style={{
                width: "100%",
              }}
            >
              {this.state.accesses.map((access) => (
                <div
                  key={`opt-access-${access.id}`}
                  style={{ textAlign: "center" }}
                >
                  {access.name}
                  <br />
                  <input
                    type="radio"
                    checked={access.id === this.state.video.access}
                    onChange={(ev) => {
                      this.setState({
                        video: {
                          ...this.state.video,
                          access: ev.target.value,
                          password:
                            access.path === "ACCESS.PROTECTED" &&
                            !this.state.video.password
                              ? this.generatePassword()
                              : this.state.video.password,
                        },
                      });
                    }}
                    value={access.id}
                  />
                  {access.path === "ACCESS.PROTECTED" ? (
                    <div>
                      <span
                        style={{
                          fontSize: "0.8em",
                        }}
                      >
                        Codice di accesso
                      </span>
                      <br />
                      <input
                        type="text"
                        style={{
                          width: "8em",
                          textAlign: "center",
                        }}
                        onChange={(ev) =>
                          this.setState({
                            video: {
                              ...this.state.video,
                              password: ev.target.value,
                            },
                          })
                        }
                        value={this.state.video.password}
                      />
                      <br />
                      <span
                        className="linker-small"
                        onClick={() => {
                          this.setState({
                            video: {
                              ...this.state.video,
                              password: this.generatePassword(),
                            },
                          });
                        }}
                      >
                        nuovo
                      </span>
                    </div>
                  ) : null}
                </div>
              ))}
            </div>

            <div className="fxrowc fxfill pdg">
              <div
                className="fxfill"
                style={{
                  color: "#787878",
                }}
              >
                Indicare chi e come può vedere il video che si sta caricando, in
                particolare scegliere tra open access (tutti possono vedere il
                video senza utilizzare una password), Protected (chiunque sia in
                possesso della password di accesso può vedere il video) oppure
                Private (solamente il proprietario del video può vederlo).
              </div>
            </div>

            <div className="fxrowc fxfill">
              <div
                style={{
                  width: "200px",
                  textAlign: "right",
                  paddingRight: "1em",
                }}
              >
                Disponibile da:
              </div>
              <div className="fxfill">
                <input
                  type="date"
                  style={{
                    width: "100%",
                  }}
                  onChange={(ev) => {
                    this.setState({
                      video: {
                        ...this.state.video,
                        valid_from: ev.target.value,
                      },
                    });
                  }}
                  value={
                    this.state.video.valid_from
                      ? this.state.video.valid_from
                      : ""
                  }
                />
              </div>
            </div>
            <div className="fxrowc fxfill">
              <div
                style={{
                  width: "200px",
                  textAlign: "right",
                  paddingRight: "1em",
                }}
              >
                Fino a:
              </div>
              <div className="fxfill">
                <input
                  type="date"
                  style={{
                    width: "100%",
                  }}
                  onChange={(ev) => {
                    this.setState({
                      video: {
                        ...this.state.video,
                        valid_to: ev.target.value,
                      },
                    });
                  }}
                  value={
                    this.state.video.valid_to ? this.state.video.valid_to : ""
                  }
                />
              </div>
            </div>

            <div className="fxrowc fxfill">
              <div
                style={{
                  width: "200px",
                }}
              />
              <div
                className="fxfill"
                style={{
                  color: "#787878",
                }}
              >
                <span
                  className="linker-small"
                  onClick={() => {
                    this.setState({
                      video: {
                        ...this.state.video,
                        valid_from: "",
                        valid_to: "",
                      },
                    });
                  }}
                >
                  rimuovi disponibilità temporale
                </span>
              </div>
            </div>

            <div className="fxrowc fxfill pdg">
              <div
                style={{
                  width: "200px",
                }}
              />
              <div
                className="fxfill"
                style={{
                  color: "#787878",
                }}
              >
                Puoi scegliere un intervallo temporale in cui il video sarà
                Disponibile in base ai diritti di accesso scelti precedentemente
                (il proprietario potra comunque accedere sempre al video
                indipendentemente dai diritti scelti)
              </div>
            </div>

            {/** LICENZA */}

            <div className="fxrowc fxfill pdgt">
              <div
                style={{
                  width: "200px",
                  textAlign: "right",
                  paddingRight: "1em",
                }}
              >
                Licenza:
              </div>
              <div className="fxfill">
                <select
                  name="lang"
                  onChange={(ev) => {
                    this.setState({
                      video: {
                        ...this.state.video,
                        license: ev.target.value,
                      },
                    });
                  }}
                  style={{
                    width: "100%",
                  }}
                  value={this.state.video.license}
                >
                  <option value="">Scegli la licenza...</option>
                  {this.state.licenses.map((license) => (
                    <option
                      key={`opt-license-${license.id}`}
                      value={license.id}
                    >
                      {license.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="fxrowc fxfill pdg">
              <div
                style={{
                  width: "200px",
                }}
              ></div>
              <div
                className="fxfill"
                style={{
                  color: "#787878",
                }}
              >
                Scegli la licenza di utilizzo
              </div>
            </div>
          </div>

          <div className="fxrowc fxfill mrg pdg">
            {this.state.saving === true ? (
              <div>Salvataggio in corso...</div>
            ) : (
              <button
                onClick={() => {
                  if (this.isValid() === true) {
                    this.setState(
                      {
                        saving: true,
                      },
                      () => {
                        addVideo(this.state.video).then((r) => {
                          this.setState(
                            {
                              saving: false,
                            },
                            () => {
                              if (
                                !this.props.match.params.hasOwnProperty("id")
                              ) {
                                alert("Upload completato.");
                                this.props.history.push(
                                  process.env.PUBLIC_URL +
                                    `/upload/${r.data.insert_bdi_videos_one.id}`
                                );
                              } else {
                                alert("Corso aggiornato.");
                              }
                            }
                          );
                        });
                      }
                    );
                  }
                }}
              >
                Salva
              </button>
            )}
          </div>
        </div>
      </BasePage>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Upload);
