import React from 'react';
import PropTypes from 'prop-types';

const Button = props => {
  return (
    <button
      className='fxfill'
      onClick={
        () => {
          props.onClick && props.onClick();
        }
      }
      // style={{
      //   width: '100%',
      //   height: '100%',
      // }}
    >
      {props.label}
    </button> 
  );
};

Button.propTypes = {
  label: PropTypes.string,
  onClick: PropTypes.func,
};

export default Button;