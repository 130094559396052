import { keycloak } from "./keycloak";

export async function fetchGraphQL(text, variables) {
  
  try {
    await keycloak.updateToken();

    // Fetch data from Hasura GraphQL API:
    const response = await fetch(
      `${process.env.PUBLIC_URL}/api/v1/graphql`,
      {
        method: 'POST',
        headers: {
          // 'X-Hasura-Admin-Secret': 'myadminsecretkey',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${keycloak.token}`,
        },
        body: JSON.stringify({
          query: text,
          variables,
        }),
      }
    );
    // Get the response as JSON
    const json = await response.json();
  
  
    // var o = 'Token Expires:\t\t' + new Date((keycloak.tokenParsed.exp + keycloak.timeSkew) * 1000).toLocaleString() + '\n';
    // o += 'Token Expires in:\t' + Math.round(keycloak.tokenParsed.exp + keycloak.timeSkew - new Date().getTime() / 1000) + ' seconds\n';
    // if (keycloak.refreshTokenParsed) {
    //     o += 'Refresh Token Expires:\t' + new Date((keycloak.refreshTokenParsed.exp + keycloak.timeSkew) * 1000).toLocaleString() + '\n';
    //     o += 'Refresh Expires in:\t' + Math.round(keycloak.refreshTokenParsed.exp + keycloak.timeSkew - new Date().getTime() / 1000) + ' seconds';
    // }
    // console.info(o);
  
    if ('errors' in json) {
      throw json.errors[0].message;
    }
    return json;
  
  } catch (err) {
    console.error('Failed to refresh token: ', err);
    window.location.reload();
  }

}

export async function uploadFile(file, properties={}) {
  const data = new FormData();
  data.append('file', file);
  for (let property in properties) {
    if (properties.hasOwnProperty(property)) {
      data.append(property, properties[property]);
    }
  }
  // console.log(data);
  const response = await window.fetch(
    `${process.env.PUBLIC_URL}/api/v1/obj`,
    {
      method: 'POST',
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      // headers: {
      //   'Content-Type': 'multipart/form-data'
      // },
      redirect: 'follow', // manual, *follow, error
      referrer: 'no-referrer', // no-referrer, *client
      body: data
    }
  );
  return response.json();
};

export function uploadVideo(file) {
  return uploadFile(
    file,
    {
      action: 'UPLOAD_VIDEO',
    },
  );
}

export function uuid_generate_v4() {
  return fetchGraphQL(`
    query {
      bdi_uuid_generate_v4 {
        uuid
      }
    }
  `);
}

export function delete_video(id) {
  return fetchGraphQL(`
    mutation deleteCourse ($id: uuid!){
      delete_bdi_videos(
        where: {
          id: {
            _eq: $id
          }
        }
      ) {
        affected_rows
      }
    }
  `, {
    "id": id,
  });
}

export function initApprovalPage() {
  return fetchGraphQL(`
    query {
      todo: bdi_courses_2_approve(
        where: {
          approved: {
            _is_null: true
          }
        }
      )
      {
        id
        title
        description
        file
        approved
        id_cat
        collocation
        thumbnail_started
        thumbnail_extracted
      }
    }
  `);
}

export function initCoursesPage(where, page, limit = 10) {

  let pageFilter = '';

  if (page) {
    pageFilter = `limit: ${limit}, offset: ${page * limit}, `;
  }

  if (where.length) {
    pageFilter = `(${pageFilter} where: {${where.join(', ')}})`;
  }

  return fetchGraphQL(`
    query {
      courses: bdi_editor_courses ${pageFilter}{
        id
        title
        description
        file
        approvations
      }
      collections: bdi_editor_courses_video_categories(
        where: {
          type: {
            _eq: "collection"
          }
        },
        distinct_on: id
      ) {
        id
        name
        description
      }
      collocation: bdi_editor_courses_collocation(
        distinct_on: id
      ) {
        id
        name
        description
      }
      typologies: bdi_editor_courses_typology (
        distinct_on: id
      ) {
        id
        name
        description
      }
      languages: bdi_editor_courses_languages (
        distinct_on: id
      ) {
        id
        name
        description
      }
      licenses: bdi_editor_courses_license (
        distinct_on: id
      ) {
        id
        name
        description
      }
      access: bdi_editor_courses_access (
        distinct_on: id
      ) {
        id
        name
        description
      }
    }
  `);
}

export function approveCourse(vid, cat, approved = true) {
  return fetchGraphQL(`
    mutation approveCourse ($cat: uuid!, $vid: uuid!, $approved: Boolean){
      update_bdi_vid_cat_by_pk(
        pk_columns: {
          cat: $cat,
          vid: $vid
        },
        _set: {
          approved: $approved
        }
      ) {
        approved
      }
    }
  `, {
    "cat": cat,
    "vid": vid,
    "approved": approved
  });
}

export function initUploadPage(id) {
  const defaultUploadPage = `
  languages: bdi_categories (
    where: {
      typ: {
        _eq: "language"
      }
    }
    order_by: {
      position: asc
    }
  ) {
    id,
    name,
    description,
    path
  }
  typologies: bdi_categories (
    where: {
      typ: {
        _eq: "typology"
      }
    }
    order_by: {
      name: asc
    }
  ) {
    id,
    name,
    description,
    path
  }
  collections: bdi_categories (
    where: {
      typ: {
        _eq: "collection"
      }
    }
    order_by: {
      name: asc
    }
  ) {
    id,
    name,
    description,
    path
  }
  collocations: bdi_categories (
    where: {
      typ: {
        _eq: "collocation"
      }
    }
    order_by: {
      path: asc
    }
  ) {
    id,
    name,
    description,
    path
  }
  accesses: bdi_categories (
    where: {
      typ: {
        _eq: "access"
      }
    }
    order_by: {
      position: asc
    }
  ) {
    id,
    name,
    description,
    path
  }
  licenses: bdi_categories (
    where: {
      typ: {
        _eq: "license"
      }
    }
    order_by: {
      position: asc
    }
  ) {
    id,
    name,
    description
  }
  bdi_uuid_generate_v4(limit: 1) {
    uuid
  }
  `;
  if (id !== undefined) {
    return fetchGraphQL(`
      query getVideo ($id: uuid!){
        video: bdi_editor_get_video(
          where: {id: {_eq: $id}}
        ) {
          id
          file
          collections
          collocation
          typology
          creation_date
          title
          description
          language
          authors
          keywords
          link_icorsi
          link_msteams
          links
          access
          password
          valid_from
          valid_to
          license
        }
        ${defaultUploadPage}
      }
    `, {
      "id": id,
    });
  }
  return fetchGraphQL(`
    query {
      ${defaultUploadPage}
    }
  `);
}

export function addVideo({
  collections = [],
  authors = [],
  links = [],
  collocation,
  typology,
  creation_date,
  title,
  description,
  language,
  link_icorsi,
  link_msteams,
  keywords,
  access,
  password,
  valid_from,
  valid_to,
  license,
  file,
  id
}) {
  
  if (id === undefined){

    // Preparing many to many collections array 
    const vid_keys = keywords.map(
      e => (`{
        keyword: {
          data: {
            text: "${e}",
          },
          on_conflict:{
            constraint: keywords_pkey,
            update_columns: id
          }
        }
      }`)
    );

    // Preparing many to many collections array 
    const vid_cats = collections.map(
      e => (`{
        cat: "${e.id}",
        typ: "collection"
      }`)
    );

    const vid_authors = authors.map(
      e => `{
        affiliation: "${e.affiliation}",
        name: "${e.name}",
        email: "${e.email}"
      }`
    );

    const vid_links = links.map(
      e => `{
        text: "${e.text}",
        url: "${e.url}"
      }`
    );

    const gql = `
      mutation InsertVideo{
        insert_bdi_videos_one(
          object: {
            collocation: "${collocation}",
            title: "${title}",
            description: "${description}",
            typology: "${typology}",
            creation_date: "${creation_date}",
            language: "${language}",
            link_icorsi: "${link_icorsi}",
            link_msteams: "${link_msteams}",
            vid_cats: {
              data: [${vid_cats.join(',')}],
            },
            vid_keys: {
              data: [${vid_keys.join(',')}],
            },
            authors: {
              data: [${vid_authors.join(',')}],
            },
            links: {
              data: [${vid_links.join(',')}],
            },
            access: "${access}",
            password: "${password}",
            valid_from: ${valid_from? `"${valid_from}"`: "null"},
            valid_to: ${valid_to? `"${valid_to}"`: "null"},
            license: "${license}",
            file: "${file}"
          }
        ) {
          id
        }
      }
    `;
    return fetchGraphQL(gql);

  }

  const vid_cats = collections.map(
    e => (`{
      vid: $id,
      cat: "${e.id}",
      typ: "collection"
    }`)
  );

  const vid_authors = authors.map(
    e => `{
      vid: "${id}",
      affiliation: "${e.affiliation}",
      name: "${e.name}",
      email: "${e.email}"
    }`
  )

  // Preparing many to many collections array 
  const vid_keys = keywords.map(
    e => (`{
      vid: $id,
      keyword: {
        data: {
          text: "${e}",
        },
        on_conflict:{
          constraint: keywords_pkey,
          update_columns: id
        }
      }
    }`)
  );

  const vid_links = links.map(
    e => `{
      vid: $id,
      text: "${e.text}",
      url: "${e.url}"
    }`
  );

  const gql = `
    mutation UpdateVideo(
      $id: uuid!,
      $collocation: uuid!,
      $typology: uuid!,
      $title: String,
      $description: String,
      $language: uuid!,
      $link_icorsi: String,
      $link_msteams: String,
      $access: uuid!,
      $password: String,
      $valid_from: date,
      $valid_to: date,
      $license: uuid!,
      $file: String
    ) {

      delete_bdi_vid_cat(
        where: {
          vid: {
            _eq: $id
          }
        }
      ) {
        affected_rows
      }

      insert_bdi_vid_cat(
        objects: [
          ${vid_cats.join(',')}
        ]
      ) {
        affected_rows
      }

      delete_bdi_authors(
        where: {
          vid: {
            _eq: $id
          }
        }
      ) {
        affected_rows
      }

      insert_bdi_authors(
        objects: [${vid_authors.join(',')}]
      ) {
        affected_rows
      }

      delete_bdi_vid_keys(
        where: {
          vid: {
            _eq: $id
          }
        }
      ) {
        affected_rows
      }

      insert_bdi_vid_keys(
        objects: [${vid_keys.join(',')}]
      ) {
        affected_rows
      }

      delete_bdi_links(
        where: {
          vid: {
            _eq: $id
          }
        }
      ) {
        affected_rows
      }

      insert_bdi_links(
        objects: [${vid_links.join(',')}]
      ) {
        affected_rows
      }

      update_bdi_videos_by_pk(
        pk_columns: {id: $id},
        _set: {
          collocation: $collocation,
          typology: $typology,
          title: $title,
          description: $description,
          language: $language,
          link_icorsi: $link_icorsi,
          link_msteams: $link_msteams,
          access: $access,
          password: $password,
          valid_from: $valid_from,
          valid_to: $valid_to,
          license: $license,
          file: $file
        }
      ) {
        id
      }
    }
  `;

  return fetchGraphQL(
    gql,
    {
      id: id,
      collocation: collocation,
      typology: typology,
      title: title,
      description: description,
      language: language,
      link_icorsi: link_icorsi,
      link_msteams: link_msteams,
      access: access,
      password: password,
      valid_from: valid_from? valid_from: null,
      valid_to: valid_to? valid_to: null,
      license: license,
      file: file
    }
  );

}
