import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Link,
  // withRouter
} from "react-router-dom";
import Center from '../common/center';
import { keycloak } from "../keycloak";

class BasePage extends Component {

  render() {
    return (
      <div
        className={`fxcol fxfill fxoverflow`}
      >
        <div
          className='fxrowc pdg'
          style={{
            height: '50px',
            backgroundColor: 'black',
            color: 'white'
          }}
        >
          <div
            className='txt-nowrap'
          >
            bDigital - {this.props.user.name}
          </div>
          <div
            className='fxfill fxrowr'
          >
            <Link

              className='mrgr pdgr linker'
              style={{
                color: 'white',
                whiteSpace: 'nowrap',
              }}
              to="/"
            >
              I tuoi corsi
            </Link>
            {
              this.props.user['x-hasura-allowed-roles'].includes('owner')?
                <Link
                  className='mrgr pdgr linker'
                  style={{
                    color: 'white',
                    whiteSpace: 'nowrap',
                  }}
                  to="/approvals"
                >
                  Approvazioni
                </Link>: null
            }
            <Link

              className='mrgr pdgr linker'
              style={{
                color: 'white',
                whiteSpace: 'nowrap',
              }}
              to="/upload"
            >
              Upload
            </Link>
            <span
              className='mrgr pdgr linker'
              style={{
                color: 'white',
                whiteSpace: 'nowrap',
              }}
              onClick={
                () => {
                  keycloak.logout();
                }
              }
            >
              Logout
            </span>
          </div>
        </div>
        <div
          className={`${this.props.flexDirection==='col'? 'fxcol': 'fxrow'} fxfill fxoverflow`}
        >
          {
            this.props.loading === true?
              <Center>
                Caricamento in corso...
              </Center>:
              this.props.children
          }
        </div>
      </div>
    );
  }
};

BasePage.propTypes = {
  loading: PropTypes.bool,
  user: PropTypes.object,
  flexDirection: PropTypes.string
};

BasePage.defaultProps = {
  flexDirection: 'col'
};

const mapStateToProps = (state) => ({
  user: state.user
});

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch: dispatch,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BasePage);