import React from 'react';
import PropTypes from 'prop-types';

const Video = props => {
  if (props.file === null) {
    return (
      <div
        className='fxfill'
      >
        Nessun video presente
      </div>
    );
  }
  return (
    <div
      className='fxfill'
    >
      <video
        width="100%"
        height="100%"
        controls
        // poster={`/video/${props.poster}`}
        onContextMenu={
          e => e.preventDefault()
        }
        poster={`/api/v1/content/${props.poster}`}
      >
        <source
          src={`/api/v1/content/${props.file}`}
          // src={'/api/v1/content/f5cd5489-07b1-41d3-a696-466cb5f1c695.mp4'}
          // type="video/webm"
        />
        Your browser does not support the video tag.
      </video> 
    </div>
  );
};

Video.propTypes = {
  file: PropTypes.string,
  poster: PropTypes.string,
};

export default Video;