import React from 'react';
import PropTypes from 'prop-types';

const Center = (props) => (
  <div
    className='fxrow fxfill'
    style={{
      alignItems: 'center',
      justifyContent: 'center'
    }}
  >
    <div>
      {props.children}
    </div>
  </div>
);

Center.propTypes = {
  children: PropTypes.any
};

export default Center;
